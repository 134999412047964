import chroma from "chroma-js";

import { Configuration, ConfigurationUpsert, MultiProjectArea, MultiReportCategory, Report } from "fond/types";
import { FilterConfiguration, LayerStyle } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

import { FamilialLayerConfig, layerConfigTemplate, sublayerTemplate } from "./configuration";

export enum CityReportLayerId {
  Npv = "cityReportAreaNpvLayer",
  Irr = "cityReportAreaIrrLayer",
  Roi = "cityReportAreaRoiLayer",
  CostPerMeter = "cityReportAreaCostPerLengthLayer",
  CostPerPassing = "cityReportAreaCostPerPassingLayer",
}

export const opacityMapping = {
  focus: {
    fill: 0.3,
    label: 1,
  },
  unfocus: {
    fill: 0.2,
    label: 0.3,
  },
};
export const unFocusColor = "grey";
export const NpvGreen = "#00C853";
export const NpvRed = "#FF1744";
const IrrColors = ["#FFE082", "#FF6F00"];
const RoiColors = ["#8C9EFF", "#4527A0"];
const CostPerMeterColors = ["#84FFFF", "#00838F"];
const CostPerPassingColors = ["#FF80AB", "#880E4F"];

export const generateCityReportMapConfiguration = (areas: MultiProjectArea[], reports: Report[]): Configuration => {
  const data = cityReportConfiguration(areas, reports);
  return {
    ID: "",
    Key: "",
    SourceID: "",
    Data: {
      ids: pickIDs(data),
      entities: Object.fromEntries(data.map((entity) => [entity.ID, entity])),
    },
    MapChildren: [],
    Type: "MapLayerConfig",
  };
};

/**
 * Create the city report configuration.
 */
export const cityReportConfiguration = (areas: MultiProjectArea[], reports: Report[]): ConfigurationUpsert => {
  const { config: byNpvLayerConfig, descendants: byNpvDescendants } = createSubareaConfigByNpv(areas, reports);
  const { config: byIrrLayerConfig, descendants: byIrrDescendants } = createSubareaConfigByAttribute(areas, reports, "Irr", IrrColors);
  const { config: byRoiLayerConfig, descendants: byRoiDescendants } = createSubareaConfigByAttribute(areas, reports, "Roi", RoiColors);
  const { config: byCostPerMeterLayerConfig, descendants: byCostPerMeterDescendants } = createSubareaConfigByAttribute(
    areas,
    reports,
    "CostPerMeter",
    CostPerMeterColors
  );
  const { config: byCostPerPassingLayerConfig, descendants: byCostPerPassingDescendants } = createSubareaConfigByAttribute(
    areas,
    reports,
    "CostPerPassing",
    CostPerPassingColors
  );

  // Assemble everything together into one group.
  const layerConfigs = [byNpvLayerConfig, byIrrLayerConfig, byRoiLayerConfig, byCostPerMeterLayerConfig, byCostPerPassingLayerConfig];
  const descendants = [...byNpvDescendants, ...byIrrDescendants, ...byRoiDescendants, ...byCostPerMeterDescendants, ...byCostPerPassingDescendants];

  return [...layerConfigs, ...descendants];
};

/**
 * Creates a layer with sublayers based on Npv
 */
const createSubareaConfigByNpv = (areas: MultiProjectArea[], reports: Report[]): FamilialLayerConfig => {
  const NpvByAreaId: Record<string, number | null> = {};
  for (const area of areas) {
    NpvByAreaId[area.ID] = reports.find((report) => report.MultiProjectArea?.ID === area.ID)?.Npv || null;
  }

  const layerConfigId = CityReportLayerId.Npv;
  const sublayerIds: string[] = [];
  const descendants = areas.flatMap(({ ID, Name }) => {
    const Npv = NpvByAreaId[ID];
    // eslint-disable-next-line no-nested-ternary
    const color = Npv ? (Npv > 0 ? NpvGreen : NpvRed) : unFocusColor;
    const fillOpacity = Npv ? opacityMapping.focus.fill : opacityMapping.unfocus.fill;
    const labelOpacity = Npv ? opacityMapping.focus.label : opacityMapping.unfocus.label;
    const strokeColor = Npv ? "black" : unFocusColor;

    const sublayerId = `${layerConfigId}-${ID}-${Npv}`;
    const filter: FilterConfiguration = { Mapbox: ["==", ["get", "boundaryId"], ID], Type: "expression" };
    const styles = layerStyles(sublayerId, color, filter, fillOpacity, strokeColor);
    const label = labelTemplate(`${sublayerId}-label`, filter, labelOpacity);
    const sublayerConfig = sublayerTemplate(sublayerId, "", layerConfigId, Name, filter, [label, ...styles]);
    sublayerIds.push(sublayerId);
    return [label, ...styles, label, sublayerConfig];
  });

  const config = layerConfigTemplate(layerConfigId, "", "Npv", [], sublayerIds);

  return { config, descendants };
};

/**
 * Creates a layer with sublayers based on the selected attribute.
 */
const createSubareaConfigByAttribute = (
  areas: MultiProjectArea[],
  reports: Report[],
  attribute: MultiReportCategory,
  palette: string[]
): FamilialLayerConfig => {
  const AttributeValueByAreaId: Record<string, number | undefined | null> = {};
  for (const area of areas) {
    const areaReport = reports.find((report) => report.MultiProjectArea?.ID === area.ID);
    AttributeValueByAreaId[area.ID] = areaReport ? areaReport[attribute] : null;
  }

  const attributeValueList = reports
    .filter((report): report is Report & { [key in typeof attribute]: number } => report[attribute] !== null)
    .map((report) => report[attribute]);
  const colorMap = assignColors(attributeValueList, palette);

  const layerConfigId = CityReportLayerId[attribute];
  const sublayerIds: string[] = [];
  const descendants = areas.flatMap(({ ID, Name }) => {
    const attributeValue = AttributeValueByAreaId[ID];
    const color = attributeValue ? colorMap?.[attributeValue] : unFocusColor;
    const fillOpacity = attributeValue ? opacityMapping.focus.fill : opacityMapping.unfocus.fill;
    const labelOpacity = attributeValue ? opacityMapping.focus.label : opacityMapping.unfocus.label;
    const strokeColor = attributeValue ? "black" : unFocusColor;

    const sublayerId = `${layerConfigId}-${ID}-${attributeValue}`;
    const filter: FilterConfiguration = { Mapbox: ["==", ["get", "boundaryId"], ID], Type: "expression" };
    const styles = layerStyles(sublayerId, color, filter, fillOpacity, strokeColor);
    const label = labelTemplate(`${sublayerId}-label`, filter, labelOpacity);
    const sublayerConfig = sublayerTemplate(sublayerId, "", layerConfigId, Name, filter, [label, ...styles]);
    sublayerIds.push(sublayerId);
    return [label, ...styles, label, sublayerConfig];
  });

  const config = layerConfigTemplate(layerConfigId, "", attribute, [], sublayerIds);

  return { config, descendants };
};

/**
 * Assigns colors to a list of numerical values, ensuring that each unique value is assigned a distinct color.
 * If the number of unique values exceeds the predefined colors, a color gradient is generated using `chroma-js`.
 *
 * @example
 * const numbers = [1, 2, 3, 2, 7];
 * const colors = ["#FFE082", "#FFCA28", "#FFB300"];
 * const result = assignColors(numbers, colors);
 * Output:
 * {
 *   1: "#FFE082",
 *   2: "#FFCA28",
 *   3: "#FFB300",
 *   7: "#FF8F00"
 * }
 *
 */
const assignColors = (numbers: number[], colors: string[]): { [number: number]: string } => {
  const uniqueValues = [...new Set(numbers)].sort((a, b) => a - b);
  const colorRange = chroma.scale(colors).mode("lab").colors(uniqueValues.length);

  const valueToColorMap: { [number: number]: string } = {};
  uniqueValues.forEach((value, index) => {
    valueToColorMap[value] = colorRange[index];
  });
  return valueToColorMap;
};

const layerStyles = (layerId: string, color: string, filter?: FilterConfiguration | null, opacity?: number, strokeColor?: string): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: `${layerId}-polygon-fill`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "fill-opacity": ["case", ["!", ["boolean", ["feature-state", "hasFocus"], true]], opacityMapping.unfocus.fill, opacity ?? 0.5],
        "fill-color": ["case", ["!", ["boolean", ["feature-state", "hasFocus"], true]], unFocusColor, color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: opacity !== undefined ? opacity : 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
  {
    ID: `${layerId}-polygon-stroke`,
    Name: `${layerId}-polygon-stroke`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "line",
      ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
      paint: {
        "line-width": 1,
        "line-opacity": ["case", ["!", ["boolean", ["feature-state", "hasFocus"], true]], opacityMapping.unfocus.fill, opacity ?? 1],
        "line-color": ["case", ["!", ["boolean", ["feature-state", "hasFocus"], true]], unFocusColor, strokeColor ?? "black"],
      },
    },
    RawStyles: {
      Type: "line",
      LineOpacity: 1,
      LineColor: "black",
      LineWidth: 2,
    },
    Type: "STYLE",
  },
];

const labelTemplate = (layerId: string, filter?: FilterConfiguration, opacity?: number): LayerStyle => ({
  ID: `${layerId}-polygon-label`,
  Name: `${layerId}-polygon-label`,
  GlobalPosition: 2,
  ConfigurationID: layerId,
  ConfigurationType: "LAYER",
  Position: 0,
  MapboxStyle: {
    ...(filter?.Mapbox ? { filter: filter.Mapbox } : {}),
    type: "symbol",
    layout: {
      "text-field": ["get", "name"],
      "text-size": 12,
    },
    paint: {
      "text-opacity": ["case", ["!", ["boolean", ["feature-state", "hasFocus"], true]], opacityMapping.unfocus.label, opacity ?? 1],
      "text-halo-width": 1.5,
      "text-halo-color": "#ffffff",
    },
  },
  RawStyles: {},
  Type: "STYLE",
});
