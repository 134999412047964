import React from "react";
import { LinearProgress, Tooltip } from "@mui/material";
import { get } from "lodash";

import { TableCell, TableRow } from "ui";

import { MultiProject } from "fond/types";

import ScoringInputForm from "./ScoringInputForm";

interface ScoringInputProps {
  config?: MultiProject["AssessConfiguration"] | null;
}

type Input = {
  id:
    | "AddressDensityImportance"
    | "AddressUnderservedProportionImportance"
    | "ProviderFiberCoverageImportance.AT&T"
    | "ProviderFiberCoverageImportance.T-Mobile"
    | "ProviderFiberCoverageImportance.Verizon"
    | "WeightedMedianIncomeImportance";
  title: string;
  description: string;
};

const inputs: Input[] = [
  {
    id: "AddressDensityImportance",
    title: "Street Frontage",
    description: "Subarea score will increase as average street frontage decreases",
  },
  {
    id: "AddressUnderservedProportionImportance",
    title: "Proportion underserved",
    description: "Subarea score will increase as the underserved proportion increases",
  },
  {
    id: "ProviderFiberCoverageImportance.AT&T",
    title: "Avoid AT&T",
    description: "Subarea score will be higher in areas where AT&T has less fiber coverage",
  },
  {
    id: "ProviderFiberCoverageImportance.T-Mobile",
    title: "Avoid T-Mobile",
    description: "Subarea score will be higher in areas where T-Mobile has less fiber coverage",
  },
  {
    id: "ProviderFiberCoverageImportance.Verizon",
    title: "Avoid Verizon",
    description: "Subarea score will be higher in areas where Verizon has less fiber coverage",
  },
  {
    id: "WeightedMedianIncomeImportance",
    title: "Median Income",
    description: "Subarea score will increase as median income increases",
  },
];

const ScoringInputs: React.FC<ScoringInputProps> = ({ config }: ScoringInputProps) => {
  const getInput = ({ id, title, description }: Input) => (
    <TableRow key={id}>
      <TableCell width={125}>
        <Tooltip title={description} placement="right-start">
          <span>{title}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        {config === undefined ? (
          <ScoringInputForm id={id} />
        ) : (
          <LinearProgress variant="determinate" value={config === null ? 50 : get(config, id) * 100} />
        )}
      </TableCell>
    </TableRow>
  );

  return inputs.map(getInput);
};

export default ScoringInputs;
